import store from '../store'
import { API_CONFIG } from '@/config/api.config.js'
import axios from 'axios'

/* eslint-disable */
export const reportDownloadMixin = {
  methods: {
    async downloadReport(reportUrl, filename, filetype, payload = null) {
      if (this.setLoadingStatus) {
        this.setLoadingStatus(true)
      }
      try {
        let reportdata = {
          report_path: reportUrl,
          request_method: !!payload ? 'POST' : 'GET',
          payload: !!payload ? JSON.stringify(payload) : null
        }
        await store.dispatch('report/getReportStream', reportdata).then((response) => {
          if (response.headers['content-type'] && response.headers['content-length'] != '0') {
            const blob = new Blob([response.data], { type: response.headers['content-type'] })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = filename + (['csv', 'excel'].includes(filetype) ? '.xlsx' : '.pdf')
            link.click()
            URL.revokeObjectURL(link.href)
          }
        })
      } catch (e) {
        console.error(e)
      } finally {
        if (this.setLoadingStatus) {
          this.setLoadingStatus(false)
        }
      }
    },

   // Code for reports for Camp reports
async downloadCampReport(siteCoreKey, orgKey, startDate, endDate) {
  try {
    let response;
    const overviewSitecorekey = 'tcCampCalendarOverviewReport'

    if (siteCoreKey === overviewSitecorekey) {
      response = await axios.get(API_CONFIG.getReportCampOverview(startDate, orgKey), {
        responseType: 'blob', // Specify the response type as 'blob'
      });
    } else {
      response = await axios.get(API_CONFIG.getReportCampDetail(startDate, endDate, orgKey), {
        responseType: 'blob', // Specify the response type as 'blob'
      });
    }

    if (response.status !== 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Create a download link
    const blob = response.data;
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;

    if (siteCoreKey === overviewSitecorekey) {
      const endDateObj = (date = new Date(startDate)) =>
        new Date(date.getFullYear(), date.getMonth() + 12 + 1, 0);

      const formattedEndDate = (date) => {
        const endDate = endDateObj(date);
        const year = endDate.getFullYear();
        const month = String(endDate.getMonth() + 1).padStart(2, '0'); 
        const day = String(endDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      endDate = formattedEndDate(new Date(startDate));

      // Set the download link filename with correct date format
      link.download = `Calendar_Overview_Report_${startDate}_${endDate}.xlsx`;
    } else {
      link.download = `Calendar_Detailed_Report_${startDate}_${endDate}.xlsx`;
    }

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading the report:', error);
  }
}},
}

export default reportDownloadMixin
